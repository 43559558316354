import React from "react";
import { Box, ContainerProps, Typography } from "@material-ui/core";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import BackNavigation from "../../../../components/back-navigation";
import UserAccountSkeleton from "../UserAccountSkeleton";
import { CustomContainer } from "./styles";
import clsx from "clsx";
import ContentModules, { ContentModuleType } from "../../../../content-modules";

const DefaultContainer = ({ ...props }: ContainerProps) => (
  <CustomContainer {...props} />
);

type Props = {
  children: React.ReactNode;
  showLoading?: boolean;
  containerWidth?: "normal" | "wide";
  customContainer?: React.ComponentType;
  title?: string | JSX.Element | JSX.Element[];
  backNavigation?: boolean | string;
  contentModules?: ContentModuleType[];
};

export default function UserAccountLayout({
  children,
  showLoading,
  containerWidth,
  customContainer,
  title,
  backNavigation,
  contentModules
}: Props) {
  const user = useAuthContextSelector(c => c.user);

  const shouldRenderLoading = !user || showLoading;

  const Container = customContainer || DefaultContainer;

  return (
    <>
      <Box
        marginTop={{ xs: 4, sm: 4 }}
        paddingBottom={{ xs: 4, md: 4 }}
        display="flex"
        flexDirection="column"
      >
        <Container
          className={clsx({
            wide: containerWidth === "wide"
          })}
        >
          {/* Back Button + Title */}
          <Box marginBottom={title ? { xs: 4, md: 4 } : 0}>
            {backNavigation && (
              <BackNavigation
                to={backNavigation === true ? "/user/account" : backNavigation}
              />
            )}

            {title && (
              <Typography variant={backNavigation ? "h5" : "h4"}>
                {title}
              </Typography>
            )}
          </Box>

          {/* Loading skeleton */}
          {shouldRenderLoading && <UserAccountSkeleton />}

          {/* Children */}
          {!shouldRenderLoading && children}
        </Container>
      </Box>

      {contentModules && !shouldRenderLoading && (
        <ContentModules contentModules={contentModules} />
      )}
    </>
  );
}
