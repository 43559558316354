import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { isEmail } from "validator";
import EmailIcon from "../../../../components/icons/email";
import { prettifyErrorMessage } from "../../../../helpers/error";
import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";
import useTranslate from "../../../../hooks/use-translate";
import { isEmailAlreadyRegistered } from "../../../../services/user";

type Props = {
  onSubmit: (email: string) => void;
  onChangeMode?: () => void;
  label?: string;
  defaultValue?: string;
  submitButtonText: string;
};

export type ChangeEmailFormDataType = {
  email?: string;
};

export default function EmailInputForm({
  onSubmit,
  submitButtonText,
  defaultValue
}: Props) {
  const t = useTranslate();
  const snackbar = useCustomSnackbar();

  const [isSubmiting, setSubmiting] = useState(false);
  const [error, setError] = useState<Error>();

  const { register, handleSubmit, errors, formState } =
    useForm<ChangeEmailFormDataType>({
      defaultValues: {
        email: defaultValue
      }
    });

  const handleFormSubmit = async (data: ChangeEmailFormDataType) => {
    const { email } = data;
    setSubmiting(true);
    try {
      await onSubmit(email);
      setSubmiting(false);
    } catch (e) {
      setSubmiting(false);
      snackbar.showError(prettifyErrorMessage(e?.message));
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box mb={2}>
        <TextField
          variant="outlined"
          id="email"
          name="email"
          autoComplete="email"
          fullWidth
          label={`${t("email")}*`}
          placeholder={t("email")}
          inputRef={register({
            required: t("error.required"),
            validate: {
              validFormat: (value: string) => {
                if (!isEmail(value)) {
                  return t("error.invalidEmail");
                }
                return true;
              }
            }
          })}
          error={!!errors.email}
          disabled={formState.isSubmitting}
          helperText={errors.email?.message}
          InputProps={{
            startAdornment: <EmailIcon />
          }}
        />

        {!error && (
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmiting || !!error}
            >
              {!isSubmiting && submitButtonText}
              {isSubmiting && <CircularProgress size={16} color="secondary" />}
            </Button>
          </Box>
        )}

        {error && (
          <Box mt={2}>
            <Alert severity="error">{error.message}</Alert>
          </Box>
        )}
      </Box>
    </form>
  );
}
