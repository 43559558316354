import React, { useState } from "react";
import useTranslate from "../../../../hooks/use-translate";
import UserAccountLayout from "../../components/UserAccountLayout";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import EmailInputForm from "./EmailInputForm";
import {
  isEmailAlreadyRegistered,
  sendUserAuthenticationEmailOtpForUpdate,
  verifyUserAuthenticationEmailOtpForUpdate
} from "../../../../services/user";
import { prettifyErrorMessage } from "../../../../helpers/error";
import EmailOtpVerificationForm from "./EmailOtpVerificationForm";
import { navigate } from "gatsby";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";

export default function ChangeEmail() {
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();
  const snackbar = useCustomSnackbar();
  const updateLocalStateUser = useAuthContextSelector(
    c => c.updateLocalStateUser
  );
  const user = useAuthContextSelector(c => c.user);

  const [step, setStep] = useState<"email" | "otp">("email");
  const [verifiedEmail, setVerifiedEmail] = useState<string>(null);

  const handleSubmitEmail = async email => {
    const isSameAsCurrentEmail = email === user.email;

    if (!isSameAsCurrentEmail) {
      const emailAlreadyRegistered = await isEmailAlreadyRegistered(email);
      if (emailAlreadyRegistered) {
        throw new Error(t("accountSettings.changeEmail.alreadyRegistered"));
      }
    }

    try {
      await sendUserAuthenticationEmailOtpForUpdate(email);
      setVerifiedEmail(email);
      setStep("otp");
    } catch (e) {
      snackbar.showError(prettifyErrorMessage(e?.message));
    }
  };

  const handleVerificationOtpSubmit = async otp => {
    try {
      const user = await verifyUserAuthenticationEmailOtpForUpdate(otp);
      updateLocalStateUser(user);
      snackbar.showSuccess(t("accountSettings.changeEmail.success"));
      navigate(getLocalizedPath("/user/account"));
    } catch (e) {
      throw e;
    }
  };

  const title = t(
    user?.email ? "accountSettings.changeEmail" : "accountSettings.addEmail"
  );

  return (
    <UserAccountLayout
      backNavigation
      title={step === "email" ? title : null}
      showLoading={!user}
    >
      {step === "email" && (
        <EmailInputForm
          onSubmit={handleSubmitEmail}
          submitButtonText={t("submit")}
          label={`${t("email")}`}
          defaultValue={user?.email}
        />
      )}

      {step === "otp" && (
        <EmailOtpVerificationForm
          email={verifiedEmail}
          onResendOtpCode={sendUserAuthenticationEmailOtpForUpdate}
          onVerificationOtpSubmit={handleVerificationOtpSubmit}
        />
      )}
    </UserAccountLayout>
  );
}
