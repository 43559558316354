import React from "react";
import { graphql } from "gatsby";
import Page from "../../../components/page";
import ProtectedRoute from "../../../containers/user/components/ProtectedRoute";
import ChangeEmail from "../../../containers/user/containers/ChangeEmail";

export const AccountSettingsChangeEmailPage = ({
  data
}: {
  data: Queries.ChangeEmailQueryDataQuery;
}) => {
  return (
    <Page {...data.datoCmsWebPage} container="fluid">
      <ProtectedRoute component={ChangeEmail} />
    </Page>
  );
};

export const query = graphql`
  query ChangeEmailQueryData($locale: String!) {
    datoCmsWebPage(
      path: { eq: "user/account/email" }
      locale: { eq: $locale }
    ) {
      ...WebPageFragment
    }
  }
`;

export default AccountSettingsChangeEmailPage;
