import { Box } from "@material-ui/core";
import React from "react";
import useTranslate from "../../hooks/use-translate";
import BackButton from "./BackButton";

type Props = {
  to?: string;
};

export default function BackNavigation({ to }: Props) {
  const t = useTranslate();
  return (
    <Box marginBottom={2}>
      <BackButton to={to}>{t("back")}</BackButton>
    </Box>
  );
}
