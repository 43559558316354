import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import { NoSsr } from "@material-ui/core";
import NoSsrPlaceholder from "../../../../components/no-ssr-placeholder";
import useIsAuthenticated from "../../../../hooks/use-is-authenticated";
import { getSignInPathWithRedirect } from "../../../../helpers/path";
import { useLocation } from "@reach/router";

type Props = RouteComponentProps<Record<string, unknown>> & {
  component: any;
  renderBeforeUserLoaded?: JSX.Element;
  redirectTo?: string;
};

const ProtectedRoute = ({
  component: Component,
  renderBeforeUserLoaded,
  redirectTo,
  ...props
}: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const user = useAuthContextSelector(c => c.user);
  const getLocalizedPath = useLocalizedLocationPath();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(
        redirectTo || getLocalizedPath(getSignInPathWithRedirect(pathname))
      );
    }
  }, [isAuthenticated]);

  if (renderBeforeUserLoaded && !user) {
    return renderBeforeUserLoaded || null;
  }

  return (
    <NoSsr fallback={<NoSsrPlaceholder />}>
      <Component {...props} />
    </NoSsr>
  );
};

export default ProtectedRoute;
