import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function UserAccountSkeleton() {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" mt={4}>
      <Skeleton width="100%" height="32px" animation="wave" />
      <Skeleton width="65%" height="32px" animation="wave" />
      <Skeleton width="45%" height="32px" animation="wave" />
    </Box>
  );
}
